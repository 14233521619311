.Top{
    background-color: #f6f6f6;
    max-width: 100%;
    overflow-x: hidden;
}
.Landing {
    min-height: calc(100vh - var(--navbar-height));
    width: 70%;
    max-width: 1900px;
    margin: 0 auto;

    /*background-color: #f6f6f6;*/
    div{
        div{
            div{

            }
        }
    }
}

.Landing > div{

}

.Landing > div:last-child > div:last-child{

}

@media (max-width: 768px){
    .Top{
        position: relative;
        /*content: attr(data-backgroundImage);*/
        background-image: url("../assets/background_full.png");

        /*background: linear-gradient(to bottom, #000000, #ffffff);*/

        /*background-repeat: no-repeat;*/

        background-size: 8000px auto;
        /*background-repeat:no-repeat;*/

        overflow: hidden;
        /*background-position: -50px -150px;*/
    }
}

img {
    max-width: 100%;
    height: auto;
}