.work-section-container {
    margin-top: 10rem;
}

.work-section-top {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.work-section-top p {
    text-align: center;
    max-width: 600px !important;
}
.work-section-top h1 {
    max-width: 700px !important;
}

/*.primary-subheading {*/
/*    text-align: center;*/
/*}*/

.work-section-bottom {
    margin-top: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.work-section-info {
    /*background-color: */
    /*flex-direction: row !important;*/
    /*gap: 20px;*/
}
.work-section-info p{
    /*font-size: 1em;*/
}

.info-pillow-img-container{
    height: 300px;
    max-width: 220px;
    overflow: hidden;
    mask-image: linear-gradient(rgba(0,0,0,1) 25%, rgba(0,0,0,0.1) 69%, rgba(0,0,0,0) 84%)
}

.work-section-info{
    /*margin: 0rem 2rem !important;*/
    /*padding: 0rem 2rem !important;*/
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
}

.work-section-pillow-info{
    /*margin: 0 !important;*/
}

.work-section-pillow-info, .work-section-info{
    width: 333px;
    max-height: 550px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    border-radius: 1rem;
    color: #505050;

    margin: 1rem 1.5rem;
    padding: 1rem 0.5rem;
}

.work-section-info h2 {
    margin: 1rem 0;
}
.work-section-info p {
    flex: 1;
    display: flex;
    align-items: center;
    /*font-weight: 600;*/
}

/*.work-section-wrapper{*/
/*    display: flex;*/
/*}*/
.info-boxes-img-container{
    display: flex;
    align-items: center;
    gap: 12px;
    max-width: 300px;
    height: 120px;
}
.info-boxes-img-container img{
    max-width: 100px;
}
.info-boxes-img-container hr{
    height: 100%;
    border: 1px solid black;
}
.info-boxes-text-container{
    display: flex;
    flex-wrap: wrap;

    min-height: 200px;
    font-size: 0.8em;
}

.vr{
    border-left: 4px solid lightgrey;
    border-radius: 10px;
    height: 100%;
    /*width: 100%;*/
}
.close-pdf-viewer{
    background-color: lightgrey;
    border-bottom-left-radius: 10px;
    width: 28px;

    position:absolute;
    top:0;
    right:0;
    z-index: 1000;
    cursor: pointer;
}
.close-pdf-viewer:hover{
    background-color: red;
    color: white;
}


@media (max-width: 768px) {
    .work-section-container {
        margin-top: 5rem !important;
    }

    .work-section-bottom {
        flex-direction: column-reverse;
        flex-wrap: nowrap;
        margin-top: 1rem !important;
    }
    .work-section-info {
        width: 190px;
    }
    .work-section-info .primary-text{
        margin: 0;
        margin-bottom: 1rem;
    }
    .work-section-info h2 {
        color: #ffffff;
        text-shadow: 0px 0px 20px black;

        /*white-space: pre-line;*/
        font-size: 1.2rem;
    }
    .info-boxes-img-container{
        flex-direction: column;
        justify-content: space-between;
        /*max-width: 120px;*/
        width: 240px;
        height: unset;
        gap: 0;
    }
    .info-boxes-img-container img{
        max-width: 100px;
    }

    .info-boxes-text-container{
        min-height: 100px;
        font-size: 0.8em;
    }

    .work-section-pillow-info, .work-section-info{
        max-height: 440px;
        margin: 1rem;
    }
    .info-pillow-img-container{
        max-width: 140px;
    }



}