.primary-heading {
    position: relative;
    /*display: inline-block;*/
    z-index: 0;

    /*content: attr(data-text);*/
    font-size: clamp(2rem, 5vw, 3rem);
    color: #4c4c4c;
    /* line-height: 5rem; */
    max-width: 600px;
    /*text-shadow: 1px 1px 2px #000;*/
    white-space: pre-wrap;

}

.primary-text {
    font-size: clamp(1rem, 3vw, 1.5rem);
    max-width: 500px;
    color: #6a6a6a;
    margin: 1.5rem 0;
    /*text-shadow: 1px 1px 1px #fff;*/
}

@media (max-width: 768px) {
    .primary-heading {
        text-align: center;
        max-width: 90%;
        color: #c2c2c2;
        text-shadow: 1px 1px 2px #000;
    }
    .primary-text {
        text-align: center;
        max-width: 80%;

        color: #a9a9a9;
    }
}