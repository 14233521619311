body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/

  /*font-family: pp-sans-big-medium,Helvetica Neue,Arial,sans-serif;*/
  font-family: 'Source Sans Pro';
  font-size: 1.0em;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /*height: 100vh;*/
  /*width: 100vw;*/


  --navbar-height: 90px;
  visibility: visible;



}

@media print{
  /* Setting no padding when printing with react-to-print */
  #container{
    gap: 0mm;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Akashi Regular';
  src: local("Akashi Regular"), url(fonts/akashi_regular.ttf) format("opentype");
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url(fonts/Roboto/Roboto-Medium.ttf) format("truetype");
  /*font-weight: bold;*/
}
