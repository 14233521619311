.contact-section-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 12rem;
}

.contact-section-container h1 {
    max-width: 900px !important;
}

.contact-form-container {
    background-color: white;
    max-width: 700px;
    width: 100%;
    margin-top: 3rem;
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: 5rem;
}

.contact-form-container input {
    flex: 1;
    height: 100%;
    border: none;
    outline: none;
    font-size: 1.3rem;
    padding: 0.5rem 1rem;
}

@media (max-width: 768px) {
    .contact-form-container {
        padding: 0.5rem !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 1rem !important;
    }
    .contact-form-container input{
        /*padding: 1rem 0;*/
        width: 100%;
    }
    .contact-form-container button{
        width: 100%;
    }
    /*.contact-form-container input {*/
    /*    font-size: 1rem !important;*/
    /*    max-width: 100%;*/
    /*    padding: 0.5rem 1rem !important;*/
    /*    margin-bottom: 0.8rem !important;*/
    /*    text-align: center;*/
    /*}*/
}