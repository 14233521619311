.home-banner-container {
    position: relative;
    display: flex;
    padding-top: 4rem;
}

.home-bannerImage-container {
    position: absolute;
    top: -100px;
    right: -380px;
    /*z-index: ;*/
    max-width: 750px;
    opacity: 0.8;
}

.home-text-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    z-index: 1;
}

@media (max-width: 1000px) {
    .home-bannerImage-container {
        max-width: 600px;
    }
}

@media (max-width: 768px) {
    .home-banner-container{
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }
    .home-bannerImage-container {
        display: none;
        /*width: 100%;*/
        /*max-width: unset;*/
        /*position: absolute;*/
        /*top: -100px;*/
        /*right: unset;*/
        /*object-fit: cover;*/
        /*right: -380px;*/
        /*!*z-index: ;*!*/
        /*max-width: unset;*/
        /*opacity: 0.8;*/
    }

    .home-text-section{
        justify-content: center;
        align-items: center;
        margin-top: 4rem;
    }

    .secondary-button {
        font-size: 1rem;
        padding: 0.8rem 2rem;
        cursor: pointer;
    }
}

/*@media (max-width: 768px) {*/
/*    .home-bannerImage-container,*/
/*    .about-background-image-container {*/
/*        display: none;*/
/*    }*/
/*}*/