.about-section-container {
    margin-top: 12rem;
    gap: 50px;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
}

.about-background-image-container{
    display: flex;
    box-shadow: 1px 1px 6px 1px black;
    /*border-inline: 6px;*/
    border-radius: 8px;
    max-width: 450px;
    transition: 0.2s;
    margin-left: clamp(0rem, 2%, 6%);
    opacity: 0.95;
}
.about-background-image-container:hover{
    transform: translateX(50px) scale(1.4);
}

.primary-subheading {
    font-weight: 700;
    color: #3498db;
    font-size: 1.15rem;
}

.about-buttons-container {
    margin-top: 2rem;
    display: flex;
}

@media (max-width: 1300px) {
    .about-background-image-container:hover{
        transform: translateX(1%) scale(1.2);
    }
}


@media (max-width: 768px) {

    .about-background-image-container{
        max-width: 250px;
        box-shadow: 1px 1px 6px 1px rgba(128, 128, 128, 0.51);
        margin-left: 0;
    }
    .about-background-image-container:hover{
        transform: scale(1.4);
    }

    .about-section-container {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        /*flex-direction: column;*/
        margin-top: 10rem;
    }

    .about-section-text-container {
        justify-content: center;
        align-items: center;
        margin-top: 4rem;
    }

    .about-buttons-container {
        justify-content: center;
        flex-direction: column;
    }
    .primary-heading, .primary-text{
        max-width: unset;
    }
}