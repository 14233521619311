h1, h2, h3, h4, h5, p {
    margin: 0;
}

main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #d9d9d9;
}

.book {
    --book-height: 100vh;
    --book-ratio: 1.3;
    --book-scale: 0.9;

    --editor-height: 250px;
    --button-width: 50px;
}

.book > div {
    height: var(--book-height);
    width: calc( (var(--book-height) / var(--book-ratio)) + 10px );
    /*overflow: auto;*/
    background-color: white;
    transform: scale(var(--book-scale));
    border-radius: 10px;
    transform-origin: left;
}

.bookCover {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 9;
    text-align: center;
    background: linear-gradient(135deg, #1c1c1c 25%, transparent 25%) -50px 0,
                linear-gradient(225deg, #1c1c1c 25%, transparent 25%) -50px 0,
                linear-gradient(315deg, #1c1c1c 25%, transparent 25%),
                linear-gradient( 45deg, #1c1c1c 25%, transparent 25%);
    background-size: 2em 2em;
    /*background-color: white;*/
    background-color: #232323;
    color: white;
    transition: transform 0.7125s;
}

.bookCover::before {
    content: "";
    position: absolute;
    width: 20px;
    right: 6%;
    top: 0;
    bottom: 0;
    background-color: #b11509;
    z-index: -1;
    /*background-color: #b11509;*/
}

.frontCover h1 {
    font-family: "Petemoss", cursive;
    font-size: 98px;
    font-weight: 300;
    color: #dbd75d;
}

.frontCover h2 {
    font-size: 16px;
}

.separator {
    --separator-size: 8px;
    width: var(--separator-size);
    height: var(--separator-size);
    background-color: #dbd75d;
    margin: 50px auto 60px auto;
    border-radius: 50%;
    position: relative;
}

.separator::after,
.separator::before {
    content: "";
    position: absolute;
    width: 12px;
    background-color: white;
    height: 2px;
    top: calc(50% - 1px);
}

.separator::after {
    left: 15px;
}

.separator::before {
    right: 15px;
}

.bookContentStyle {
    display: flex;
    flex-direction: column;
    height: auto;
    /*grid-template-rows: auto var(--editor-height);*/

    /*transform: translateY(30px);*/
    /*transform: scale(var(--book-scale)) translateY(30px);*/
}

.bookContent {
    /*z-index: 2;*/
    height: 100%;
    /*padding-top: 10px;*/
    /*border: 10px solid ;*/
    /*transform: scale(var(--book-scale)) translateY(30px);*/
    background-color: white !important;
    transition: all 0.3s 1s;
    overflow: auto;
}

.bookContent h3,
.bookContent p {
    /*opacity: 0;*/
    transition: all 0.3s 0.3s;
}

.bookEntry {
    background-color: lightgrey;
}

.bookEntry > h3 {
    /*padding: 30px;*/
}

.bookEntry > p {
    padding: 0px 0px 10px 10px;
    text-align: justify;
    font-size: 0.8em;
    line-height: 1.8em;
    transition: all 0.05s;
}

.bookCover{
    background-color: black !important;
}

.bookCover > .frontCover {
    transition: opacity 0.2s 0.2s;
}
.bookCover > .backCover{
    transition: opacity 0.3s 0.3s;
}

.open > .bookCover {
    transform: rotateY(180deg) scale(var(--book-scale));
}
.open > .bookCover > .backCover {
    transform: rotateY(180deg) scale(var(--book-scale));
}

.backCover > div {
    transition: opacity 1s 1s !important;
}

.open > .bookCover > .frontCover {
    opacity: 0;
}

/*.open > .bookCover > div {*/
/*    opacity: 0;*/
/*}*/

.open > .bookContent {
    transform: scale(var(--book-scale)) translateY(0px);
}

.open > .bookContent h3,
.open > .bookContent p {
    opacity: 1;
}

.open > .bookCover > .backCover{

    position: absolute;
    display: flex;
    justify-content: flex-end;
    height: 100%;
    width: 100%;
}

.emptyHeading{
    color: grey;
    font-style: italic;
}

/*Paper-Layout*/
.paper {
    position: relative;
    width: calc(100% - 0px);
    /*max-width: 800px;*/
    min-width: 400px;
    /*noinspection CssInvalidPropertyValue*/
    height: -webkit-fill-available;
    margin: 0 auto;
    background: #fafafa;
    border-radius: 10px;

    box-shadow: 0 2px 8px rgba(0,0,0,.3);
    overflow: hidden;
}
.paper:before {
    content: '';
    position: absolute;
    top: 0; bottom: 0; left: 0;
    width: 60px;
    background: radial-gradient(#575450 6px, transparent 7px) repeat-y;
    background-size: 30px 30px;
    border-right: 3px solid #D44147;
    box-sizing: border-box;
}

.paperSheet,
.paperSheet::before,
.paperSheet::after {
    /* Add shadow to distinguish sheets from one another */
    box-shadow: 2px 1px 1px rgba(0,0,0,0.15);
}
.paperSheet::before,
.paperSheet::after {
    content: "";
    position: absolute;
    width: 100%;
    /*height: 100%;*/
    background-color: #eee;
    border-radius: 5px;
    /*border-left: 5px solid black;*/
}

/* Second sheet of paperSheet */
.paperSheet::before {
    left: 5px;
    top: 5px;
    z-index: -1;
}

/* Third sheet of paperSheet */
.paperSheet::after {
    /*background-color: black;*/
    left: 10px;
    top: 10px;
    z-index: -2;
}


.paperContent {
    /*padding-left: 60px;*/
    position: absolute;
    top: 10px;
    right: 0;
    bottom: 10px;
    left: 0px;
    /*background: linear-gradient(transparent, transparent 28px, #91D1D3 32px);*/
    /*background-size: 30px 30px;*/
}

/*Look of the Paper*/
.paperContent > div[id="paperLook"]{

    min-height: 100%;
    height: max-content;

    padding-inline-start: 70px;
    padding-inline-end: 10px;
    background: linear-gradient(transparent, transparent 28px, #91D1D3 32px);
    background-size: 30px 30px;
}

.paperContent > div {
    width: 100%;
    max-width: 100%;
    /*height: 100%;*/
    /*max-height: 100%;*/
    line-height: 30px;
    padding: 0 10px;
    border: 0;
    outline: 0;
    /*background: transparent;*/
    color: black;
    /*color: mediumblue;*/
    font-family: 'Handlee', cursive;
    font-weight: bold;
    font-size: 18px;
    box-sizing: border-box;
    z-index: 1;
}
/* Firework when unlock diary */
@keyframes firework {
    0% {
        /*transform: translate(0%, 60vh);*/
        /*width: 0.5vmin;*/
        opacity: 1;
    }
    /*50% {*/
    /*    width: 0.5vmin;*/
    /*    opacity: 1;*/
    /*}*/
    100% {
        width: 45vmin;
        opacity: 0;
    }
}

.firework,
.firework::before,
.firework::after {
    --top: 60vh;
    content: "";
    position: absolute;
    top: 38.7%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0.5vmin;
    aspect-ratio: 1;
    background:
        /* random backgrounds */
            radial-gradient(circle, #3498db 0.2vmin, #0000 0) 50% 00%,
            radial-gradient(circle, #3498db 0.3vmin, #0000 0) 00% 50%,
            radial-gradient(circle, #3498db 0.5vmin, #0000 0) 50% 99%,
            radial-gradient(circle, #3498db 0.2vmin, #0000 0) 99% 50%,
            radial-gradient(circle, #3498db 0.3vmin, #0000 0) 80% 90%,
            radial-gradient(circle, #3498db 0.5vmin, #0000 0) 95% 90%,
            radial-gradient(circle, #3498db 0.5vmin, #0000 0) 10% 60%,
            radial-gradient(circle, #3498db 0.2vmin, #0000 0) 31% 80%,
            radial-gradient(circle, #3498db 0.3vmin, #0000 0) 80% 10%,
            radial-gradient(circle, #3498db 0.2vmin, #0000 0) 90% 23%,
            radial-gradient(circle, #3498db 0.3vmin, #0000 0) 45% 20%,
            radial-gradient(circle, #3498db 0.5vmin, #0000 0) 13% 24%
;
    background-size: 0.5vmin 0.5vmin;
    background-repeat: no-repeat;
    animation: firework 1.5s linear;
    opacity: 0;
}

.firework::before {
    transform: translate(-50%, -50%) rotate(25deg) !important;
}

.firework::after {
    transform: translate(-50%, -50%) rotate(-37deg) !important;
}