.faq-section-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /*margin: 6rem 0rem;*/
    margin-top: 10rem;
    margin-bottom: 10rem;
    /*padding-inline: 10%;*/
}

.accordion{
    width: 100%;
    /*padding-inline: 10%;*/
    max-width: 1024px;
}

.faq-collapse-container{
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    width: 100%;
}

.accordion-item{
    width: 100%;
    flex-direction: column;
    background-color: rgba(211, 211, 211, 0.49);
    color: #4c4c4c;
    border-radius: 12px;
    margin: 1rem 0;
    overflow: hidden;
}

.accordion-title{
    display: flex;
    justify-content: space-between;

    /*padding-block: 12px;*/
    /*padding-inline: 12px;*/
    padding: 20px 30px;
    cursor: pointer;

    font-size: 1.4rem;
    /*border-bottom: 2px solid grey;*/
}

.accordion-expand-icon{
    display: flex;
    align-items: center;
    transition: 0.1s;
}

.accordion-content{
    background-color: lightgrey;
    overflow: hidden;
    padding-inline: 2em;
    transition:
            height 0.2s ease-in-out,
            padding-bottom 0.2s ease-in-out;
}
.accordion-content p{
    text-align: left;
    font-size: 1.1rem;
    max-width: unset;
    color: #3e3e3e;
}

.additional-content{

}

.provider{
    display: grid;
    grid-column: 1/span 2;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    margin: 2rem 10rem;
    justify-items: center;
}

/*@media (max-width: 1700px) {*/
/*    .faq-section-container{*/
/*        padding-inline: 4rem;*/
/*    }*/
/*}*/
/*@media (max-width: 1024px) {*/
/*    .faq-section-container{*/
/*        padding-inline: 0rem;*/
/*    }*/
/*}*/

@media (max-width: 768px) {
    .faq-section-container{
        padding-inline: 0%;
    }
    .accordion-item {
        background-color: rgba(211, 211, 211, 0.49);
        color: white;
    }
    .accordion-title{
        font-size: 1.2rem;
    }
    .accordion-content p{
        color: #3e3e3e;
    }
    .accordion-content h3{
        color: #3e3e3e;
        font-weight: 600;
    }
    .provider{
        display: grid;
        grid-column: 1/span 2;
        grid-template-columns: 1fr 1fr 1fr;
        margin: 1rem 0;
    }
}