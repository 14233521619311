.privacy-page{
    line-height: 1.5;
}

.privacy-page a{
    text-decoration: none;
    color: #3498db;
    font-family: "Barlow",Arial,sans-serif;
    font-weight: bold;
}

.privacy-container{
    display: grid;
    grid-template-areas: "links content";
    grid-auto-columns: 20% 80%;
    background-color: white;
    min-height: calc(100vh - var(--navbar-height));
}

.privacy-links-container{
    grid-area: links;
    margin-top: 48px;
    padding-inline-start: 24px;
}
.privacy-links-container label{
    font-size: 24px;
    font-weight: bold;
    line-height: 4rem;
}
.privacy-links-container > ul{
    list-style-type: none;
    padding-inline-start: 12px;
}

.privacy-links-container > ul > li{
    margin-bottom: 1rem;
}
.privacy-links-container > ul > li > a {
    font-weight: normal;
}

.privacy-content-container{
    grid-area: content;
    border-radius: 6px;
    padding: 0 3rem;
    /*margin-top: 3rem;*/
    max-width: 640px;
    margin-top: 48px;
}

.privacy-content-section{
    /*padding: 24px 0 0 0;*/
}
.privacy-content-section > hr{
    border: 1px inset rgba(238, 238, 238, 0.3);
}

h2[class*="legal-heading"]{
    margin-top: 0;
}

/*h2{*/
/*    margin-top: 48px;*/
/*    !*margin-bottom: 32px;*!*/
/*}*/
.privacy-content-container p{
    font-size: clamp(1rem, 2vw, 1rem);
    color: #6a6a6a;
    margin: 1.5rem 0;
}
li{
    color: #6a6a6a;
    margin-bottom: 0.25em;
}

/*h1[class="primary-heading"]{*/
/*    font-size: 1.8em;*/
/*    text-align: left;*/
/*    color: black;*/
/*    max-width: unset;*/
/*}*/

/*h2[class="primary-heading"]{*/
/*    font-size: 1.4em;*/
/*    text-align: left;*/
/*    color: black;*/
/*}*/

.primary-privacy-text{
    font-size: clamp(1rem, 2vw, 1rem);
    color: #6a6a6a;
    margin: 1.5rem 0;
}



@media (max-width: 768px) {

    .privacy-page {
        width: unset !important;
    }

    .privacy-container{
        display: grid;
        grid-template-areas:
            "content"
            "links"
        ;
        /*grid-auto-rows: 20% 80%;*/
        grid-auto-columns: 100% 100%;
        background-color: white;

        /*font-size: 0.8em;*/
    }

    .privacy-content-container{
        padding: 48px 24px;
        margin: 0;
    }

    h1[class*="legal-heading"]{
        font-size: 2em;
        text-align: left;
        color: black;
    }

    h2[class*="legal-heading"]{
        font-size: 1.4em;
        text-align: left;
        color: black;
    }

    .legal-text{
        text-align: left;
        margin: 0.5rem 0;
    }
}
