.testimonial-section-bottom {
    margin-top: 2rem;
    background-color: lightgrey;
    /*background-color: white;*/
    padding: 1.5rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 550px;
    border-radius: 2rem;
    flex-direction: column;
    text-align: center;
}
.testimonial-section-bottom {
    margin: 2rem auto;
}
.testimonial-section-bottom img{
    max-width: 200px;
    box-shadow: 1px 1px 8px 1px;
    cursor: pointer;
}

.testimonial-section-bottom p {
    font-weight: 700;
    color: #515151;
    max-width: 500px;
    font-size: 1rem;
    margin: 2rem 0rem;
}
.testimonial-section-bottom h2 {
    margin-top: 1.0rem;
}
.testimonial-stars-container svg {
    margin: 0rem 0.25rem;
    font-size: 1.5rem;
    color: #3498db;
}
.testimonial-section-pdfviewer{
    width: 80%;
    height: 90%;
}

@media (max-width: 768px) {
    .testimonial-section-pdfviewer{
        width: 100%;
        height: 100%;
    }
}